import i18n from "@/plugins/i18n";

const entityFilter = {
  namespaced: true,
  state: {
    data: "",
    options: []
  },
  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options;
    },
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    setData(context, value) {
      context.commit("SET_DATA", value);
    },
    getOptions(context) {
      context.commit("SET_OPTIONS", [
        {
          name: i18n.t("adapters.adapter.filter.entity.sku.label"),
          code: 'sku'
        },
        {
          name: i18n.t("adapters.adapter.filter.entity.virtual_stock.label"),
          code: 'virtual_stock'
        }
      ]);
    },
  },
  getters: {
    data: state => {
      return state.data;
    },
    options: state => {
      return state.options.map(option => {
        return {
          text: option.name,
          value: option.code,
          icon: ''
        };
      });
    },
    toParams: state => {
      return [
        {column: "entity", value: state.data},
      ];
    }
  }
};
export default entityFilter;
