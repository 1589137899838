import InventoryService from "@/services/InventoryService";

const settings = {
  namespaced: true,
  state: {
    settings: null,
    loading: false
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    load({ commit }) {
      commit("SET_LOADING", true)
      InventoryService.settings.all()
        .then(json => {
          if (json) {
            commit("SET_SETTINGS", json)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        });
    },
  },

  getters: {
    get: (state) => (group, name) => {
      const result = state.settings[group][name]
      if (result) {
        return JSON.parse(result)
      }
    },
    group: (state) => (group) => {
      return state.settings[group]
    },
    has: (state) => (group, name) => {
      return !!(state.settings[group][name]);
    },
    settings: (state) => state.settings
  }
};

export default settings;
