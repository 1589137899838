import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import virtuals from "@/store/modules/adapters/virtuals.js";
import entityFilter from "@/store/modules/adapters/filters/entityFilter.js";

const adaptersOverview = {
  requestController: null,
  requestControllerAlt: null,
  namespaced: true,
  modules: {
    virtuals,
    entityFilter
  },
  state: {
    ...tableState,
    adapter: null,
    adapters: [],
  },
  mutations: {
    ...tableMutations,
    SET_ADAPTER(state, adapter) {
      state.adapter = adapter;
    },
    SET_ADAPTERS(state, adapters) {
      state.adapters = adapters;
    },
  },
  actions: {
    ...tableActions,
    fetchAdapters({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        InventoryService.adapters.list(params)
          .then(json => {
            if (json) {
              commit("SET_ADAPTERS", json.items);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    searchAdapters(context) {
      const params = [];
      const defaultParams = [];

      if (context.state.defaultFilters.length || context.state.filters.length) {
        context.state.defaultFilters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              defaultParams.push(JSON.parse(JSON.stringify(filterParams[i])));
            }
          }
        });
        context.state.filters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              params.push(JSON.parse(JSON.stringify(filterParams[i])));
            }
          }
        });
      }
      const allParams = [...defaultParams, ...params]
      if (allParams.length) {
        context.commit("SET_SEARCH", allParams.filter(param => {
          return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []
        }));
      }
      context.dispatch("fetchAdapters", true);
    },
    fetchItems({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        InventoryService.targets.list(params)
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    selectAdapter({ commit }, item) {
      if (item !== null) {
        commit("SET_ADAPTER", item);
        commit("SET_ITEM", item);
      } else {
        commit("SET_ADAPTER", null);
        commit("SET_ITEM", null);
      }
    },
    edit({commit}, adapter) {
      return new Promise((resolve, reject) => {
        InventoryService.targets.update(adapter.id, adapter)
          .then((adapter) => {
            commit("SET_ADAPTER", adapter);
            commit("EDIT_ITEM", adapter);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    add({commit}, adapter) {
      return new Promise((resolve, reject) => {
        InventoryService.targets.create(adapter)
          .then((data) => {
            commit("SET_ADAPTER", data);
            commit("ADD_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters
  }
};

export default adaptersOverview;
