const customFieldFilter = {
    namespaced: true,
    state: {
        data: { custom1: null, custom2: null, custom3: null, custom4: null, custom5:  null, custom6: null }
    },
    mutations: {
        SET_DATA(state, data) {
            state.data[data.column] = data.data;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            let params = [];
            Object.keys(state.data).forEach( index => {

                if (state.data[index]) {
                    params.push({ column: index, value: state.data[index] });
                }
            })

            return params;
        }
    }
};

export default customFieldFilter;
